import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { debounce } from 'lodash'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import uiStore from 'src/ui/reducers'
import { formatMessage, FormattedMessage } from '../../translations'
import { contentLangs } from '../../config'

import { deepGet } from '../../shared/obj'
import Icon from '../../shared/icons'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

import { censhareStore } from '../reducer'

import * as css from '../styles.scss'

export default class CenshareContent extends Component {

  static propTypes = {
    page: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    onClose: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.censhareArticleIds = (props.page.meta.censhareArticleIds || []).slice()
    this.state = {
      isLoading: false,
      isSaving: false,
      censhareArticles: [],
      currentCenshareArticle: {},
      searchValue: props.page.name,
      categories: [],
      selectedCategory: '-1',
      total: 0,
      count: 0,
      connected: this.censhareArticleIds.length,
      sortBy: 'asc',
    }

    this.psLeft = null
    this.psRight = null

    // Minified load to get the total amount of
    // censhare articles in the system wihtout any filter applied
    censhareStore.metaLoad()
      .then((result) => {
        this.setState({
          total: deepGet(result, 'meta.pagination.total'),
        })
      })

    const languageKey = Object.keys(contentLangs).find(key => key === props.page.createdIso)
    const languageIso = languageKey && contentLangs[languageKey]
    censhareStore.languageIso = languageIso && languageIso.censhareIso

    this.handleCategoryLoad()
    this.handleLoad()

    this.debouncedLoad = debounce(this.handleLoad, 500)
  }

  @autobind
  savePage() {
    this.props.page.meta.censhareArticleIds = this.censhareArticleIds.slice()
    this.setState({
      isSaving: true
    })
    return this.props.page.save()
      .then(() => {
        this.setState({
          isSaving: false
        })
      })
  }

  @autobind
  handleLoad() {

    if (this.state.isLoading) {
      // Bail out if already loading
      return
    }

    const sortState = [{ name: this.state.sortBy }]
    const params = {
      sorting: JSON.stringify(sortState),
      languageIso: censhareStore.languageIso,
      page: censhareStore.page,
      limit: censhareStore.limit,
      filter: []
    }

    if (this.state.searchValue) {
      const filterState = { name: encodeURIComponent(this.state.searchValue) }
      params.filter.push(filterState)
    }

    if (this.state.selectedCategory !== '-1') {
      const filterState = { category: this.state.selectedCategory }
      params.filter.push(filterState)
    }

    params.filter = JSON.stringify(params.filter)

    this.setState({
      isLoading: true
    })

    censhareStore.loadMultiple({ params })
      .then((result) => {

        // Check page connection and add to the censhare model
        if (this.censhareArticleIds) {
          result.models.forEach((censhareArticle) => {
            const censhareId = this.censhareArticleIds
              .find(el => el === censhareArticle.assetId)

            if (censhareId) {
              censhareArticle.connected = true
            }
          })
        }

        this.setState({
          currentCenshareArticle: result.models[0] || {}, // select first entry
          censhareArticles: result.models,
          count: deepGet(result, 'meta.pagination.count'), // of the current response
          isLoading: false
        })
        setTimeout(() => {
          this.psLeft = new PerfectScrollbar('#contentBoxBodyLeft-container', {
            suppressScrollX: true
          })
          this.psRight = new PerfectScrollbar('#contentBoxBodyRight-container', {
            suppressScrollX: true
          })
        }, 0)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  handleCategoryLoad() {
    const opts = {
      action: 'category',
      method: 'get',
      params: {
        // Category names only exist in german or english, therefor use ui language
        languageIso: uiStore.language
      }
    }

    return censhareStore.loadCategories(null, null, opts)
      .then((res) => {
        const categories = deepGet(res, 'body.data') || []

        if (categories.length > 0) {
          categories.splice(0, 0, {
            assetId: '-1',
            name: formatMessage({ id: 'censhare.categories' }),
            key: '-1'
          })

          this.setState({ categories })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  handleSearch({ target }) {
    this.setState({
      searchValue: target.value
    }, () => this.debouncedLoad())
  }

  @autobind
  handleClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  @autobind
  handleSelectDropdownEntry({ target }) {
    this.setState({
      selectedCategory: target.value
    }, () => this.debouncedLoad())
  }

  @autobind
  handleArticleSelect(article) {
    this.setState({
      currentCenshareArticle: article
    })
  }

  @autobind
  handleToggle(article) {
    if (this.state.isSaving) {
      // Bail out if saving
      return null
    }

    const { page } = this.props
    article.connected = !article.connected

    if (article.connected) {
      this.censhareArticleIds.splice(0, 0, article.assetId)
    }
    else {
      const index = this.censhareArticleIds.indexOf(article.assetId)
      if (index > -1) {
        this.censhareArticleIds.splice(index, 1)
      }
    }

    this.setState({
      connected: this.censhareArticleIds.length
    })

    return this.savePage()
  }

  @autobind
  handleSort() {
    const { sortBy } = this.state
    this.setState({
      sortBy: sortBy === 'asc' ? 'desc' : 'asc'
    }, this.handleLoad)
  }

  @autobind
  handleClear() {
    this.handleSearch({ target: { value: '' } })
    this.focusInput()
  }

  @autobind
  focusInput() {
    if (this.ref) {
      this.ref.focus()
    }
  }

  /*
  * Currently unused - Censhare API does not offer a pagination
  * TODO: If added in the future, a filter reset (handleClear) and a sort (handleSort) must be added to the pagination request
  */
  @autobind
  handleScroll({ target }) {
    const bottomOfWindow = target.scrollTop >= target.scrollHeight - 250 - target.offsetHeight
    if (bottomOfWindow) {

      if (this.isLoading) {
        // Bail out if already loading
        return
      }

      this.isLoading = true

      // If there are entries not loaded yet
      if (this.state.count < this.state.total) {

        // Reset filter first
        censhareStore.filter = []

        // Add search bar value
        if (this.state.searchValue) {
          const filterState = { name: encodeURIComponent(this.state.searchValue) }
          censhareStore.filter.push(filterState)
        }

        // Add category dropdown value
        if (this.state.selectedCategory !== '-1') {
          const filterState = { category: this.state.selectedCategory }
          censhareStore.filter.push(filterState)
        }

        censhareStore.filter = JSON.stringify(censhareStore.filter)

        censhareStore.nextPage()
          .then(() => {
            this.isLoading = false
          })
      }
    }
  }

  renderDropdown() {
    const { categories, selectedCategory, isLoading } = this.state

    return (<select
      id="category-filter"
      className={css.dropdownContainer}
      value={selectedCategory}
      onChange={this.handleSelectDropdownEntry}
      disabled={isLoading}
    >
      {categories.map((el) => {
        return (<option
          key={el.assetId}
          value={el.key}
        >
          {el.name}
        </option>)
      })}
    </select>)
  }

  renderArticleBlock(article) {
    const isSelected = article.assetId === this.state.currentCenshareArticle.assetId

    return (<div className={classNames(css.articleBlock, isSelected ? css.selected : '')}>
      <div
        className={css.articleBlockLeft}
        onClick={() => this.handleArticleSelect(article)}
      >
        <div>{article.name}</div>

        {article.createdAt
          ? <span>{article.createdAt} | </span>
          : null}

        {article.category
          ? <span>{article.category} | </span>
          : null}

        <span>ID {article.assetId}</span>
      </div>
      <div
        className={classNames(css.switchHolder, this.state.isSaving ? css.saveOverlay : '')}
        onClick={() => this.handleToggle(article)}
      >
        {article.isConnected
          ? <div className="switch on" aria-hidden="true"></div>
          : <div className="switch off" aria-hidden="true"></div>}
      </div>
    </div>)
  }

  renderArticleContentBlock(content) {
    return (<div className={css.contentBlock}>
      {content.name}
    </div>)
  }

  renderLoader() {
    return (<ContentLoadingBox
      spinner
      message={{ id: 'censhare.loading' }}
    />)
  }

  render() {
    const {
      page
    } = this.props

    const { count, total, connected, currentCenshareArticle, isLoading, sortBy, searchValue, categories } = this.state

    const selectedText = formatMessage({ id: 'censhare.selected-count' }, { count, total })
    const connectedText = formatMessage({ id: 'censhare.connected' }, { connected })
    const assetText = formatMessage({ id: 'censhare.assets' }, { count: currentCenshareArticle.content ? currentCenshareArticle.content.length : 0 })

    if (this.psLeft && this.psRight) {
      this.psLeft.update()
      this.psRight.update()
    }

    return (
      <div className={css.censhareContent}>
        <div className={css.upperContent}>
          <label className={css.searchFieldWrapper}>
            <FormattedMessage id="filter.search" />
            <input
              key="search"
              type="text"
              id="search"
              ref={(ref) => {
                this.ref = ref
              }}
              className={css.searchField}
              onChange={this.handleSearch}
              value={searchValue}
              disabled={isLoading}
            />
            <div
              key="icon-wrapper"
              onClick={this.handleClear}
              className={classNames(css.ionClose, searchValue ? '' : css.empty)}
            >
              <i className="ion-close-round"></i>
            </div>
          </label>

          {categories.length > 0
            ? this.renderDropdown()
            : null}

          <div className={css.upperContentRight}>
            <div className={css.desc}>
              <FormattedMessage id="censhare.page-name" />
            </div>
            <span>{ page.name }</span>
          </div>
        </div>

        <div className={css.lowerContent}>
          {isLoading
            ? this.renderLoader()
            : [<div key="content-box1" className={css.contentBox}>
              <div className={css.contentBoxHeader}>
                <div onClick={this.handleSort} className={css.sortByName}>
                  <FormattedMessage id="censhare.article" />
                  <span className="geneva-arrow">
                    {sortBy === 'asc'
                      ? <Icon name="ion ion-ios-arrow-up" />
                      : <Icon name="ion ion-ios-arrow-down" />}
                  </span>
                </div>
                <span className={css.selectedText}>{ selectedText }</span>
                <span>{ connectedText }</span>
              </div>

              <div
                id="contentBoxBodyLeft-container"
                className={css.contentBoxBody}
                ref={(ref) => {
                  this.refLeft = ref
                  if (ref) {
                    // Currently unused
                    // this.refLeft.addEventListener('scroll', this.handleScroll, false)
                  }
                }}
              >
                {this.state.censhareArticles.map((article) => {
                  return this.renderArticleBlock(article)
                })}
              </div>
            </div>,
            <div key="content-box2" className={css.contentBox}>
              <div className={css.contentBoxHeader}>
                <span>{currentCenshareArticle.name}</span>
                <span className={css.selectedText}>{assetText}</span>
              </div>
              <div
                id="contentBoxBodyRight-container"
                className={css.contentBoxBody}
                ref={(ref) => {
                  this.refRight = ref
                  if (ref) {
                    // Currently unused
                    // this.refRight.addEventListener('scroll', this.handleScroll, false)
                  }
                }}
              >
                {currentCenshareArticle.content && currentCenshareArticle.content.map((content) => {
                  return this.renderArticleContentBlock(content)
                })}
              </div>
            </div>]
          }
        </div>
      </div>
    )
  }

}
